<template>
  <a
    class="item-menu"
    v-bind:class="[(item.customize && item.customize.disable_onetap && item.customize.disable_onetap == true) ? 'item-combo' : '', classCols]"
    data-toggle="tooltip"
    v-bind:title="item.description"
  >
    <div class="inner" :data-synth="'menu-item-' + item.uuid" v-bind:class="{ 'hight-line-item': is_hight_line }">
        <div class="while-tooltip">
          <div class="menu-image bg1" @click="(item.first_layers && item.first_layers.length > 1) && show()">
            <img v-if="item.image" :src="item.image.desktop_thumbnail" :alt="item.name"/>
            <change-button v-if="item.first_layers"></change-button>
          </div>
          <div
            @click="show()"
            class="menu-name"
            style="z-index: 1; font-family: 'SharpSemiBold Sans'; font-size: 17px"
          >
            {{ item.name }}
          </div>
        </div>
          <div
            v-if="(item.customize && item.customize.disable_onetap && item.customize.disable_onetap == true)"
          >
            <div class="fw600 pr10 t16" style="z-index: 1;border-top: 1px solid #f1f1f1;" v-if="checkPosition(this)">
              <div style="width: 100%; display: flex; flex: 1" class="hide">
                <i class="icon-delivery xs-mr-5 xs-hide md-block hide"></i>
                <i class="icon-collection xs-mr-5 xs-hide md-block hide"></i>
              </div>
              <div style="width: 100%; text-align: right; line-height: 30px">
                <div v-if="$const.COMBO_PRICE_VIEW_MODE === 0">
                  <div v-if="item.display_price > 0">
                    <span v-if="!isNaN(item.display_price)" v-html="generatePrice(item)"></span>
                    <span v-else>
                      {{ item.display_price }}
                    </span>
                  </div>
                  <div v-else class="t13" style="line-height: 32px">&nbsp;</div>
                </div>
                <div v-if="$const.COMBO_PRICE_VIEW_MODE === 1">
                  <div v-if="item.price > 0">
                    <span v-if="!isNaN(item.display_price)" v-html="generatePrice_otp2(item)"></span>
                    <span v-else>
                      {{ item.display_price }}
                    </span>
                  </div>
                  <div v-else class="t13">&nbsp;</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div
              v-if="item.marketing_description"
              class="xs-hide menu-description  marketing_description"
              style="z-index: 1"
            >
              {{ item.marketing_description }}
            </div>
            <div v-if="hasOptions(item) && !requiresCustomization(item) && !(item.customize && item.customize.disable_onetap && item.customize.disable_onetap == true)" class="menu-info-content">
              <span
                style="display: block; padding: 10px 10px 5px; font-size: 14px; font-weight: 400"
                >{{ $t('menu.select_size_crust') }}
              </span>
              <div class="select-size">
                <select v-model="selected_crust_size">
                  <option
                    v-for="(option, index) in crust_size"
                    :value="option"
                    :key="index + 0"
                  >
                    <!--{{convertSizeName(option)}}-->
                    {{ option.name }}
                  </option>
                </select>
                <i
                  class="icon-caret-down--black ml-auto absolute pin-r pin-t pin-b mt-10 mr-5"
                  style="width: 20px; height: 20px; pointer-events: none; background-color: #e9ebee"
                ></i>
              </div>
            </div>
            <div v-else-if="crust_size && crust_size[0] && !requiresCustomization(item) && !(item.customize && item.customize.disable_onetap && item.customize.disable_onetap == true)">
              <span
                style="display: block; padding: 10px 10px 5px; font-size: 14px; font-weight: 600">
                {{crust_size[0].name}}
              </span>
            </div>
            <div class="menu-price" style="z-index: 1;display: block;">
              <!-- pizza and rest -->
              <div
                :class="`btn btn-success btn-add-single-item text-left${loading && user_action === ADD_TO_CART ? ' disabled' : ''}`"
                :data-synth="'add-single-item-commence-' + item.uuid"
                style="width: 100%; padding: 8px 9px; display:flex;"
                v-if="requiresCustomization(item)"
                @click="show()">
                <span style="flex: 3 1 50%;text-align: center;">
                  {{ $t('pizza_commence') }}
                </span>
              </div>
              <div
                :class="`btn btn-success btn-add-single-item text-left${loading && user_action === ADD_TO_CART ? ' disabled' : ''}`"
                :data-synth="'add-single-item-basket-' + item.uuid"
                style="width: 100%; padding: 8px 9px; display:flex;"
                v-else-if="hasOptions(item) && !requiresCustomization(item)"
                v-on:click.stop="addToBasket"
              >
                <span style="flex: 3 1 50%;text-align: center;">
                {{ $t('button_add') }}
                </span>
                <span class="pull-right" style="flex: 1 1 0%;"
                  >{{ $const.CURRENCY
                  }}{{ formatNum(selected_crust_size.price) }}</span
                >
              </div>
              <div
                v-else
                :class="`btn btn-success btn-add-single-item text-left${loading && user_action === ADD_TO_CART ? ' disabled' : ''}`"
                :data-synth="'add-single-item-basket-' + item.uuid"
                style="width: 100%; padding: 8px 9px; display:flex;"
                v-on:click.stop="addToBasket"
              >
                <span style="flex: 3 1 50%;text-align: center;">
                {{ $t('button_add') }}
                </span>
                <span class="pull-right" style="flex: 1 1 0%;"
                  >{{ $const.CURRENCY
                  }}{{ formatNum(item.price || item.display_price) }}</span
                >
              </div>
            </div>
          </div>
    </div>
  </a>
</template>
<script>
import { MAPPED_ACTIONS } from '../../utils/map-actions'
import ChangeButton from '@/components/partials/ChangeButton'
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    ChangeButton
  },
  props: ['item', 'cols', 'uuid', 'onlyLunch', 'is_hight_line'],
  computed: {
    ...mapState(['loading', 'user_action']),
    ...mapGetters({
      selectedCategory: 'selectedCategory',
      selectedSubCategory: 'selectedSubCategory'
    })
  },
  watch: {
    selected_crust_size(newVal, oldVal) {
      if (oldVal && oldVal.puuid != '') {
        this.chooseVariant(newVal.first, newVal.second)
      }
    }
  },
  mounted() {
    var vm = this
    if (this.uuid && this.uuid == this.item.uuid) {
      this.show()
    }
    vm.getCrustSize(this.item)
  },
  data() {
    return {
      classCols: 'cols-' + this.cols,
      crust_size: [],
      selected_crust_size: {
        value: 0,
        name: '',
        price: 0,
        puuid: '',
        cuuid: ''
      },
      ...MAPPED_ACTIONS
    }
  },
  methods: {
    /**
     * Check if pizza is forcibly customizable
     */
    requiresCustomization(item) {
      return item.min_extra > 0
    },
    generatePrice (item) {
      return this.$t('format_currency', { currency: this.$const.CURRENCY, amount: this.formatNumDisplay(item.display_price), start: '<span class="t13"><span class="font-08em cl-78838c fw-normal">', end: '</span></span>' })
    },
    generatePrice_otp2 (item) {
      return this.$t('format_currency_otp2', { currency: this.$const.CURRENCY, price: this.formatNumDisplay(item.price), start: '<span class="t13"><span class="font-08em cl-78838c fw-normal">', end: '</span></span>', display_price: this.formatNumDisplay(item.display_price), start_display_price: '<span class="text-red display-price">', end_display_price: '</span>'})
    },
    /**
     * Chosen first and second layer
     */
    chooseVariant(first, second) {
      let product = this.lodash.cloneDeep(this.item)
      let choosedFirst = this.lodash.cloneDeep(first)
      let choosedSecond = this.lodash.cloneDeep(second)
      product.first_layers = []
      product.first_layers.push(choosedFirst)
      if (first != null && !this.empty(first)) {
        product.first_layers[0].second_layers = [choosedSecond]
      }
      product.price = this.selected_crust_size.price
      this.eventTrackingProductView(null, product)
      this.evenTrackingProductClick(
        this,
        product,
        'product-selection-from-variant-list'
      )
      this.eventTrackingProductDetail(
        this,
        product,
        this.$const.CARD_ITEM_TYPE.SINGLE,
        'product-details'
      )
    },
    getCrustSize(item) {
      if (item && item.first_layers) {
        for (let p in item.first_layers) {
          let parent = item.first_layers[p]
          if (!parent.sku.includes('1/2 ')) {
            for (let c in parent.second_layers) {
              if (c) {
                let child = parent.second_layers[c]
                let temp = {
                  value: child.id,
                  name: parent.name + ' ' + child.name,
                  price: item.price + parent.price + child.price, // total price = item price + first layer price + second layer price
                  puuid: parent.uuid,
                  first: parent,
                  second: child,
                  cuuid: child.uuid
                }
                this.crust_size.push(temp)
              }
            }
          }
        }

        if (this.crust_size.length > 0) {
          this.selected_crust_size = this.crust_size[0]
        }
      }
    },
    hasPrice(item) {
      return (
        item &&
        item.first_layers &&
        item.first_layers.length >= 1 &&
        item.first_layers[0].second_layers &&
        item.first_layers[0].second_layers.length >= 1
      )
    },
    hasOptions(item) {
      return (
        item &&
        item.first_layers &&
        (item.first_layers.length > 1 ||
          (item.first_layers.length == 1 &&
            item.first_layers[0].second_layers.length > 1))
      )
    },
    /**
     * adding item to cart
     * */
    addToBasket() {
      this.$store.dispatch('setUserAction', this.ADD_TO_CART)
      if (this.loading) { return }
      if (!this.checkPosition(this)) {
        this.$router.push({'name': 'home', query: { focusLocalization: true }})
      } else {
        let pro = {
          id: this.item.uuid,
          name: this.item.name,
          price: 0,
          quantity: 1,
          item: this.item,
          first: {},
          second: {}
        }
        let firstSelected = this.selected_crust_size
          ? this.selected_crust_size.first
          : {}
        let secondSelected = this.selected_crust_size
          ? this.selected_crust_size.second
          : {}
        let price = 0

        // add to cart
        let item = {
          group: {},
          selling_start: this.item.selling_start,
          products: []
        }

        item.group['outlet_code'] = this.getOutletCode(this)
        item.group['tier'] = this.$cart.tier
        item.group['order_type'] = this.$cart.order_type
        item.group['order_time'] = this.getTimePickerDate(this)
        item.group['product_type'] = this.item.product_type
        item.group['combo_id'] = null
        item.group['quantity'] = 1
        item.price = secondSelected ? secondSelected.price : null
        item.item = this.lodash.cloneDeep(this.item)
        item.first = firstSelected
        item.second = secondSelected
        let product = {}
        this.item.variantId = '';
        product.category_id = this.$cart.category_id
        product.combo_group_id = null
        product.product_id = this.item.uuid
        product.first_id = this.selected_crust_size
          ? this.selected_crust_size.puuid
          : null
        product.second_id = this.selected_crust_size
          ? this.selected_crust_size.cuuid
          : null

        product.toppings = {}
        product.toppings.remove = []
        product.toppings.extra = []
        item.products.push(product)

        this.$cart.insert(this, item)
        pro.price = item.item.display_price
        pro.first = firstSelected
        pro.second = secondSelected
        pro.category = this.selectedCategory.name
        if (pro.category === 'Pizzas') {
          this.item.variantId = this.selected_crust_size.first.first_id + '-' + this.selected_crust_size.second.second_id
        }
        this.evenTrackingProductClick(this, this.item, 'add-to-cart')
        this.eventTrackingAddToCart(
          this,
          pro,
          'main',
          this.$const.CARD_ITEM_TYPE.SINGLE
        )


        // NOTE: Remove crust upsell modal
        // check item options and see if there are upsells available
        // let thereAreUpSells = false
        // if (item.first && item.second) {
        //   const secondLayerPrice = parseFloat(item.second.price)
        //   for (let secondLayer of item.first.second_layers) {
        //     if (secondLayerPrice < parseFloat(secondLayer.price)) {
        //       thereAreUpSells = true
        //     }
        //   }
        // }

        // if there are upsell options, open the upsell modal
        // if (thereAreUpSells === true) {
        //   const clonedItem = this.lodash.cloneDeep(this.item)
        //   item.price = price
        //   this.$modal.show(
        //     ModalUpSell,
        //     {
        //       previousItem: item,
        //       outletCode: this.getOutletCode(this),
        //       tier: this.$cart.tier,
        //       orderType: this.$cart.order_type,
        //       orderTime: this.getTimePickerDate(this),
        //       categoryId: this.$cart.category_id,
        //       item: clonedItem
        //     },
        //     {
        //       class: 'modal-upsell',
        //       transition: 'nice-modal-fade',
        //       overlayTransition: 'overlay-fade',
        //       clickToClose: false,
        //       name: 'close-modal-upsell'
        //     }
        //   )
        // } else {
        // }
      }
    },
    addToCart(item) {},
    /**
     * Trigger before open modal popup
     * */
    beforeOpen(event) {
      document.body.classList.add('modal-open')
    },
    /**
     * Trigger before close modal popup
     * */
    beforeClose(event) {
      document.body.classList.remove('modal-open')
    },
    /**
     * Show modal popup
     * */
    show(isHalf = false) {
      let vm = this
      if (!this.checkPosition(this)) {
        this.$router.push({ name: 'home', query: {focusLocalization: true} })
      } else {
        if (this.item.first_layers) {
          let slug = this.selectedCategory ? this.selectedCategory.slug : null
          let slugSub = this.selectedSubCategory
            ? this.selectedSubCategory.slug
            : null
          if (isHalf) {
            let itemData = { uuid: vm.$const.HALF_HALF_UUID }

            if (this.onlyLunch) {
              itemData.uuid = vm.$const.HALF_HALF_LUNCH_UUID
              vm.redirectToMenuPage(
                slug,
                vm.$const.HEADER_MENU.HALF_HAlF,
                vm.$const.HEADER_MENU.LUNCH_SET
              )
            } else {
              vm.redirectToMenuPage(slug, vm.$const.HEADER_MENU.HALF_HAlF)
            }
            this.evenTrackingProductClick(
              this,
              itemData,
              'direct-product-selection'
            )
          } else {
            this.evenTrackingProductClick(
              this,
              this.item,
              'direct-product-selection'
            )
            vm.redirectToMenuPage(slug, slugSub, vm.item.uuid)
          }
        } else {
          let slug = this.selectedCategory ? this.selectedCategory.slug : null
          let slugSub = this.selectedSubCategory ? this.selectedSubCategory.slug : null
          this.evenTrackingProductClick(this, this.item, 'direct-product-selection')
          vm.redirectToItemPage(slug, slugSub, this.item.uuid)
        }
      }
    },
    convertSizeName(obj) {
      if (
        this.selected_crust_size &&
        obj &&
        obj.name == this.selected_crust_size.name
      ) {
        return this.subStringSizeName(obj.name)
      } else {
        return obj.name
      }
    },
    subStringSizeName(str) {
      let length = window.innerWidth <= 768 ? 15 : 24
      if (str.length > length) {
        return str.substring(0, length) + '...'
      } else {
        return str
      }
    }
  }
}
</script>
<style scoped>
select {
  box-shadow: none;
  font-size: 14px;
}

.icon-caret-down--black {
  position: absolute;
  width: 20px;
  height: 20px;
  pointer-events: none;
  background-image: url('../../assets/images/icons/icon-arrow-down.svg');
}

/* CAUTION: IE hackery ahead */
/*select::-ms-expand {*/
/*display: none; !* remove default arrow on ie10 and ie11 *!*/
/*}*/

/* target Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width: 0\0) {
  /*select {*/
  /*background:none\9;*/
  /*padding: 5px\9;*/
  /*}*/
}
</style>
<style scoped>
@media (max-width: 990px) {
  .list-item-menu .item-menu.mode-view-list {
    width: calc(100% - 12px) !important;
    display: flex;
    flex-direction: row;
    padding-bottom: 0;
  }
}

.list-item-menu .item-menu.mode-view-list {
  width: calc(100% - 12px);
  padding-bottom: 0;
}

.list-item-menu .item-menu.mode-view-list .menu-image {
  height: 100%;
  min-height: 160px;
}

.list-item-menu .item-menu.mode-view-list .flag-customise {
  height: 30px;
  width: 95%;
  background: hsla(0, 0%, 100%, 0.9);
  position: absolute;
  top: 30%;
  left: 0;
  text-align: center;
  border: 2px solid hsla(0, 0%, 100%, 0.9);
  border-radius: 25px;
}
</style>
