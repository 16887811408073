<template>
  <button class="item-change-button absolute pin-r top-0 p-5 mt-10 mr-10 w-auto rounded-full text-11 border-grey-lighter text-black bg-white hover:opacity-100">
    <span v-html="$t('pizza_modificar')"></span>
  </button>
</template>
<script>

export default {
  created () { },
  data () {
    return {
      cart: this.$cart
    }
  },
  watch: { },
  computed: { },
  mounted () { },
  methods: { }
}
</script>
<style scoped>
  .item-change-button {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    filter: alpha(opacity=90);
    -moz-opacity: 0.9;
    -khtml-opacity: 0.9;
    opacity: 0.9;

    -webkit-transition: all .3s ease-in-out;
    -moz-transition:    all .3s ease-in-out;
    -o-transition:      all .3s ease-in-out;
    -ms-transition:     all .3s ease-in-out;
    transition:         all .3s ease-in-out;
  }
  .item-change-button:hover {
    opacity: 1;
  }
</style>